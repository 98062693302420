<template>
  <div class="modalbodypay">
    <div
      class="modalfooter"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -26px;
      "
    >
      <p style="text-align: left; width: 738px; font-size: 16px">
        Please select the cleansed fields you wish to export below. Original
        supplied data will remain at the beginning of the spreadsheet. If you
        wish to remove this, please tick the option below.
      </p>
      <div
        style="display: flex; align-items: center; justify-content: end"
        class="mb-2"
      >
        <button
          type="button"
          class="btn btn-primary mr-5"
          @click="selectAll()"
          style="font-size: 17px; color: white"
        >
          Select All
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="clearSelectedTags()"
          style="font-size: 17px"
        >
          Clear Selection
        </button>
      </div>
    </div>

    <div>
      <div>
        <div style="width: 1250px; height: 500px">
          <template>
            <div class="tag-container">
              <TagItem
                v-for="tag in tagOptions"
                :key="tag"
                :tag="tag"
                :is-selected="selectedTags.includes(tag)"
                @toggle="toggleTagSelection"
                @remove="toggleDelete"
              >
              </TagItem>
            </div>
          </template>
        </div>
        <br />
      </div>

      <div
        class="modalfooter"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 20px;
        "
      >
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            v-model="checkboxValue"
            value="option1"
            style="font-size: 17px"
          />
          <label
            class="form-check-label"
            for="inlineCheckbox1"
            style="font-size: 17px"
            >Remove Supplied data</label
          >
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="downloadcheck()"
          style="font-size: 17px"
        >
          Download Selected
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import TagItem from "./tagItems.vue";

export default {
  name: "downloadmodal",
  components: {
    TagItem,
  },
  props: ["currentTask_id", "currentTask_title"],
  data() {
    return {
      outputcolumnalter: false,
      selectedTags: [],
      formValue: [],
      tagOptions: [],
      allData: [],
      customview: false,
      apiLink: "",
      checkboxValue: false,
    };
  },
  watch: {
    // formValue(newVal, oldVal) {
    //   const removed = oldVal.filter((v) => !newVal.includes(v));
    //   console.log(removed);
    // },
  },

  mounted() {
    this.getvalue();
  },

  methods: {
    selectAll() {
      this.selectedTags = this.tagOptions;
      this.saveSelectedTags();
    },
    toggleTagSelection(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter((t) => t !== tag);
      } else {
        this.selectedTags.push(tag);
      }
      this.saveSelectedTags();
    },

    toggleDelete(tag) {
      this.tagOptions = this.tagOptions.filter((t) => t !== tag);
      this.saveSelectedTags();
    },

    getvalue($flag = false) {
      ApiService.get("/apps/todo/task/getOutputcolumns/" + this.currentTask_id)
        .then((response) => {
          if ($flag) {
            this.allData = response.data.outputcolumn;
            this.formValue = response.data.outputcolumn;
          } else {
            this.allData = response.data.outputcolumn;
            this.formValue = response.data.outputcolumnnew;
            if (!this.formValue) {
              this.formValue = this.allData;
            }
          }

          if (this.allData.length !== this.formValue.length) {
            this.tagOptions = this.allData;
            this.selectedTags = this.formValue;
          } else {
            this.tagOptions = this.allData;
            this.selectedTags = [];
          }

          if (response.data.outputremovesuppdata) {
            this.checkboxValue = true;
          }

          this.loadSelectedTags();
        })
        .catch(() => {
          this.allData = [];
          this.formValue = [];
        });
    },

    saveSelectedTags() {
      if (this.currentTask_id) {
        localStorage.setItem(
          `selectedTags_${this.currentTask_id}`,
          JSON.stringify(this.selectedTags)
        );
      }
    },

    loadSelectedTags() {
      if (this.currentTask_id) {
        const savedTags = localStorage.getItem(
          `selectedTags_${this.currentTask_id}`
        );
        if (savedTags) {
          this.selectedTags = JSON.parse(savedTags);
        }
      }
    },

    clearSelectedTags() {
      if (this.currentTask_id) {
        this.selectedTags = [];
        localStorage.removeItem(`selectedTags_${this.currentTask_id}`);
      }
    },

    downloadcheck($flag = false) {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      if (!$flag) {
        if (this.selectedTags.length !== 0) {
          this.formValue = this.selectedTags;
        }

        let outputcolumns_altered = true;
        let formArray = [];

        if (
          (this.allData.length !== this.formValue.length &&
            this.allData.length > 0) ||
          this.checkboxValue
        ) {
          formArray = this.formValue;
          ApiService.post(
            `/apps/todo/task/OutputcolumnsAltered/${this.currentTask_id}`,
            {
              outputcolumnalter: outputcolumns_altered,
              outputcolumnnew: formArray,
              outputremovesuppdata: this.checkboxValue,
            }
          )
            .then(() => {
              this.downloadfile();
            })
            .catch(() => {});
        } else {
          outputcolumns_altered = false;
          formArray = this.allData;
          ApiService.post(
            `/apps/todo/task/OutputcolumnsAltered/${this.currentTask_id}`,
            {
              outputcolumnalter: outputcolumns_altered,
              outputcolumnnew: formArray,
              outputremovesuppdata: this.checkboxValue,
            }
          )
            .then(() => {})
            .catch(() => {});

          this.downloadfile(true);
        }
      } else {
        this.downloadfile();
      }
    },

    downloadfile(dflag = false) {
      Swal.close();
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      if (dflag) {
        this.apiLink = `apps/todo/task/downloadall/${this.currentTask_id}`;
      } else {
        this.apiLink = `apps/todo/task/downloadselected/${this.currentTask_id}`;
      }
      ApiService.setHeader();
      ApiService.getblob(this.apiLink)
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          const date1 = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
          let outputFile = this.currentTask_title;
          const flagWord = dflag ? "ALL" : "CUSTOM";
          outputFile = `${outputFile}_RMDCLEANSE_${flagWord}_${date1}`
            .replace(".", "_")
            .replace("/", "_");
          outputFile = `${outputFile}.xlsx`;

          fileLink.href = fileURL;
          fileLink.setAttribute("download", outputFile);
          document.body.appendChild(fileLink);

          Swal.close();

          fileLink.click();
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry, Download Error. Try Later",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
  },

  created() {
    this.loadSelectedTags();
  },
};
</script>
<style scoped>
.modal_left_text {
  font-size: 17px;
  font-weight: 500;
}

.modal_right_text {
  font-size: 17px;
  font-weight: 500;
}

.modalfooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalRow {
  align-items: center;
  margin-top: 15px;
}

/* modal */
#modal1 .modal-header {
  border-bottom: 0 none;
}

#modal1 .modal-header .close {
  float: right;
  font-size: 31px;
  font-weight: 700;
  color: #000;
}

#modal1 .modal-dialog {
  position: relative;
  background-color: #e9eef3;
  position: absolute;
  top: 80px;
  right: 29px;
  width: 500px;
}

#inputBox {
  border: 1px solid #ccc;
  font-size: 14px;
  padding-right: 20px;
}

#inputBox.grayedOut {
  background-color: #eee;
}

.closeIcon {
  position: absolute;
  color: #6f7392;
  width: 24px;
  height: 24px;
  margin-left: 97px;
  margin-top: -32px;
  cursor: pointer;
}
.form-check {
  margin-right: 625px;
}

.tag-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  white-space: nowrap;
}
</style>
