<template>
  <div class="modalbodypay">
    <div class="d-flex modalRow">
      <div class="modal_left_text col-sm-6">Total Records</div>
      <div class="modal_right_text col-sm-6 d-flex justify-content-end">
        <span>{{ totalRecords }}</span>
      </div>
    </div>
    <div class="d-flex modalRow">
      <div class="modal_left_text col-sm-6">Cost</div>
      <div class="modal_right_text col-sm-6 d-flex justify-content-end">
        <span>£ {{ chargeAmount.toFixed(2) }} (Ex.VAT)</span>
      </div>
    </div>

    <div class="d-flex modalRow">
      <div class="modal_left_text col-sm-6">{{ discountMessage }}</div>
      <div class="modal_right_text col-sm-6 d-flex justify-content-end">
        <span>-£ {{ discountAmount.toFixed(2) }} (Ex.VAT)</span>
      </div>
    </div>

    <div class="d-flex modalRow">
      <div class="modal_left_text col-sm-6">Discount code</div>
      <div class="modal_right_text col-sm-6 d-flex">
        <div class="form-view">
          <input
            type="Text"
            id="inputBox"
            v-model="discountCode"
            class="form-control"
            :class="{ grayedOut: isGrayedOut }"
            placeholder="Enter discount code here"
          />
          <button type="button" class="closeIcon" v-if="discountCode !== ''" @click="clearInput">
            <i class="fa fa-times" aria-hidden="true" style="color: red"></i>
          </button>
        </div>
        <div>
          <button
            type="button"
            v-on:click="checkDiscountCode"
            class="btn btn-warning ml-5 text-dark font-weight-bold"
            style="font-size: 15px"
          >
            Enter
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <p class="text-danger" style="font-size: 12px">{{ errorMessage }}</p>
      </div>
    </div>

    <div class="d-flex modalRow">
      <div class="modal_left_text col-sm-6">Final Price</div>
      <div class="modal_right_text col-sm-6 d-flex justify-content-end">
        <span class="text-right">£ {{ nettChargeAmount.toFixed(2) }} (Ex.VAT)</span>
      </div>
    </div>

    <div class="modalfooter">
      <button type="button" class="btn btn-primary mt-5" @click="doPay()" style="font-size: 17px">Pay Now</button>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  name: "purchasemodal",
  props: ["currentTask_id"],
  data() {
    return {
      discountMessage: "Free Record Discount",
      errorMessage: ".",
      currentTask: null,
      showCurrentTask: false,
      totalRecords: 0,
      chargeAmount: 0,
      discountAmount: 0,
      nettChargeAmount: 0,
      discountCode: "",
    };
  },
  mounted() {
    this.checkDiscountCode();
  },
  computed: {
    isGrayedOut() {
      return this.discountCode === "";
    },
  },
  methods: {
    clearInput() {
      this.discountCode = "";
      this.checkDiscountCode();
    },
    checkDiscountCode() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      const userData = {
        discountcode: this.discountCode,
      };

      ApiService.setHeader();
      ApiService.post("apps/todo/task/stripedata/" + this.currentTask_id, userData)
        .then(({ data }) => {
          if (data.status.couponexist == true) {
            this.discountMessage = "Total Discount";
            this.errorMessage = " " + data.status.message ? data.status.message : "";
          } else {
            this.discountMessage = "Free Record Discount ";
            if (this.discountCode != "") {
              this.errorMessage = "Discount Code Invalid " + data.status.message ? " " + data.status.message : "";
            } else {
              this.errorMessage = " " + data.status.message ? data.status.message : "";
            }
          }
          this.totalRecords = data.totalRecords;
          this.chargeAmount = parseFloat(data.charge);
          this.discountAmount = parseFloat(data.discount);
          this.nettChargeAmount = parseFloat(data.nettcharge);
          if (data.discountvalid == false) {
            this.discountCode = "";
          }
          Swal.close();
        })
        .catch(() => {
          if (this.discountCode != "") {
            this.errorMessage = "Discount Code Invalid";
          } else {
            this.errorMessage = "xxx";
          }
          Swal.close();

          // Swal.fire({
          //   title:
          //     "Sorry we can not process your cost now",
          //   confirmButtonClass: "btn btn-secondary"
          // });
        });
    },

    doPay() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      const userData = {
        discountcode: this.discountCode,
      };

      ApiService.setHeader();
      ApiService.post("apps/todo/task/stripecheckout/" + this.currentTask_id, userData)
        .then(({ data }) => {
          Swal.close();
          window.location = data;
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry we can not take a payment at the moment please try later",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
  },
};
</script>
<style scoped>
.modal_left_text {
  font-size: 17px;
  font-weight: 500;
}

.modal_right_text {
  font-size: 17px;
  font-weight: 500;
}

.modalfooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalRow {
  align-items: center;
  margin-top: 15px;
}

/* modal */
#modal1 .modal-header {
  border-bottom: 0 none;
}

#modal1 .modal-header .close {
  float: right;
  font-size: 31px;
  font-weight: 700;
  color: #000;
}

#modal1 .modal-dialog {
  position: relative;
  background-color: #e9eef3;
  position: absolute;
  top: 80px;
  right: 29px;
  width: 500px;
}

#inputBox {
  border: 1px solid #ccc;
  font-size: 14px;
  padding-right: 20px;
}

#inputBox.grayedOut {
  background-color: #eee;
}

.closeIcon {
  position: absolute;
  color: #6f7392;
  width: 24px;
  height: 24px;
  margin-left: 97px;
  margin-top: -32px;
  cursor: pointer;
}
</style>
